<template>
  <div>
    <el-autocomplete
      v-model="text"
      :fetch-suggestions="handleAutocomplete"
      @select="handleSelect"
    ></el-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'googlePlace',
  data() {
    return {
      location: '',
      searchResults: [],
      service: null,
      text: null,
    };
  },
  created() {
    const googlePlaceScript = document.createElement('script');
    googlePlaceScript.setAttribute(
      'src',
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyD-qyI2hYw8B4uJtq6kMbPvL0nnkNae6-w&libraries=places',
    );
    document.head.appendChild(googlePlaceScript);
  },
  methods: {
    handleAutocomplete(queryString, cb) {
      if (this.service == null) {
        this.service = new window.google.maps.places.AutocompleteService();
      }
      const placeCallback = {
        autocompleteCallback: cb,
        handleGooglePlace(predictions, status) {
          if (status == window.google.maps.places.PlacesServiceStatus.OK) {
            this.autocompleteCallback(
              predictions.map((prediction) => ({
                value: prediction.description,
                place_id: prediction.place_id,
                name: prediction.desciprtion,
              })),
            );
          } else {
            this.autocompleteCallback([]);
          }
        },
      };
      this.service.getPlacePredictions(
        {
          input: this.text,
          componentRestrictions: {
            country: 'ca',
          },
        },
        placeCallback.handleGooglePlace.bind(placeCallback),
      );
    },
    handleSelect(value) {
      const map = new google.maps.Map(document.createElement('div'));
      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails(
        {
          placeId: value.place_id,
          fields: ['name', 'address_component'],
        },
        (place, status) => {
          if (status == window.google.maps.places.PlacesServiceStatus.OK) {
            let city = null;
            const sublocality = place.address_components.find((c) => c.types.includes('sublocality'));
            if (sublocality == null) {
              const locality = place.address_components.find((c) => c.types.includes('locality'));
              if (locality != null) {
                city = locality.short_name;
              }
            } else {
              city = sublocality.short_name;
            }
            const postalCodeResult = place.address_components.find((c) => c.types.includes('postal_code'));
            let postalCode = null;
            if (postalCodeResult != null) {
              postalCode = postalCodeResult.short_name;
            }

            const provinceResult = place.address_components.find((c) => c.types.includes('administrative_area_level_1'));
            let province = null;
            if (provinceResult != null) {
              province = provinceResult.short_name;
            }
            const result = {
              placeId: value.place_id,
              street: place.name,
              city,
              province,
              postalCode,
            };
            this.$emit('place', result);
          }
        },
      );
    },
  },
};
</script>
<style>
.el-autocomplete {
  width: 500px;
}
</style>
