<template>
  <el-card class="form-container" shadow="never">
    <el-form :model="merchant" :rules="rules" ref="merchantForm" label-width="150px">
      <el-form-item label="Name(中文):" prop="localizedName.chs">
        <el-input v-model="merchant.localizedName.chs"></el-input>
      </el-form-item>
      <el-form-item label="Name(English):" prop="localizedName.eng">
        <el-input v-model="merchant.localizedName.eng"></el-input>
      </el-form-item>
      <el-form-item label="Sku Section:" prop="skuSection">
        <el-input v-model="merchant.skuSection"></el-input>
      </el-form-item>
      <el-form-item label="Bulk Threshold：" prop="bulkThreshold">
        <el-input v-model="merchant.bulkThreshold"></el-input>
      </el-form-item>
      <el-form-item label="Merchant Email:" prop="email">
        <el-input v-model="merchant.email"></el-input>
      </el-form-item>
      <el-form-item label="Merchant Phone:" prop="phoneNumber">
        <el-input v-model="merchant.phoneNumber"></el-input>
      </el-form-item>
      <el-form-item label="Color Input" prop="color">
        <el-input v-model="merchant.color"></el-input>
      </el-form-item>
      <el-form-item label="Full Address:">
        <google-place @place="handlePlaceResult"></google-place>
      </el-form-item>
      <el-form-item label="Street Address:" prop="address">
        <el-input v-model="merchant.address"></el-input>
      </el-form-item>
      <el-form-item label="City:" prop="city">
        <el-input v-model="merchant.city"></el-input>
      </el-form-item>
      <el-form-item label="Province:" prop="province">
        <el-input v-model="merchant.province"></el-input>
      </el-form-item>
      <el-form-item label="Postal Code:" prop="postalCode">
        <el-input v-model="merchant.postalCode"></el-input>
      </el-form-item>
      <el-form-item label="Google Place Id" prop="googlePlaceId">
        <el-input v-model="merchant.googlePlaceId" disabled></el-input>
      </el-form-item>
      <el-form-item label="Bulk Start Day" prop="bulkStartDay">
        <el-select v-model="merchant.bulkStartDay">
          <el-option v-for="item in dayOptions" :key="item.label" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Bulk End Day" prop="bulkEndDay">
        <el-select v-model="merchant.bulkEndDay">
          <el-option v-for="item in dayOptions" :key="item.label" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Display in Home" prop="displayInHomePage">
        <el-checkbox v-model="merchant.displayInHomePage"></el-checkbox>
      </el-form-item>
      <el-form-item label="Primary Image">
        <single-upload v-model="primaryPic"></single-upload>
      </el-form-item>
      <el-form-item label="Other Images">
        <multi-upload v-model="selectMerchantPics" :inputList="inputPics"></multi-upload>
      </el-form-item>
      <el-form-item label="Thumbnail">
        <single-upload v-model="merchant.thumbnail"></single-upload>
      </el-form-item>
      <el-form-item label="Merchant Tags:" prop="merchantTags">
        <div v-for="(item, index) in merchant.merchantTags" :key="index">
          <el-input v-model="item.tag"></el-input>
          <el-checkbox v-model="item.primary">Is Primary</el-checkbox>
          <el-button type="mini" @click="removeTag(index)">Delete</el-button>
        </div>
        <el-button type="mini" @click="addTag()">Add</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('merchantForm')">提交</el-button>
        <el-button v-if="!isEdit" @click="resetForm('merchantForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { createMerchant, getMerchant, updateMerchant } from '@/api/merchant';
import MultiUpload from '@/components/Upload/multiUpload';
import SingleUpload from '@/components/Upload/singleUpload';
import GooglePlace from '@/views/utils/Place';

const defaultMerchant = {
  images: [],
  localizedName: {
    chs: '',
    eng: '',
  },
  phoneNumber: '',
  email: '',
  address: '',
  city: '',
  province: '',
  postalCode: '',
  googlePlaceId: null,
  color: null,
  merchantTags: [],
  skuSection: null,
  bulkThreshold: null,
  bulkStartDay: null,
  bulkEndDay: null,
  displayInHomePage: false,
  thumbnail: null,
};
const DEFAULT_RULE = [
  {
    required: true,
    message: 'This field cannot be empty',
    trigger: 'blur',
  },
  {
    min: 2,
    max: 140,
    message: '2-140 characters',
    trigger: 'blur',
  },
];
export default {
  name: 'MerchantDetail',
  components: { MultiUpload, SingleUpload, GooglePlace },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      merchant: { ...defaultMerchant },
      dayOptions: [
        { label: 'Monday', value: 'Monday' },
        { label: 'Tuesday', value: 'Tuesday' },
        { label: 'Wednesday', value: 'Wednesday' },
        { label: 'Thursday', value: 'Thursday' },
        { label: 'Friday', value: 'Friday' },
        { label: 'Saturday', value: 'Saturday' },
        { label: 'Sunday', value: 'Sunday' },
      ],
      selectMerchantPics: [],
      inputPics: [],
      primaryPic: null,
      selectStartDay: null,
      selectEndDay: null,
      googlePlace: {
        location: '',
        searchResults: [],
        service: null,
      },
      rules: {
        name: DEFAULT_RULE,
        skuSection: DEFAULT_RULE,
        email: DEFAULT_RULE,
        phoneNumber: DEFAULT_RULE,
        address: DEFAULT_RULE,
        city: DEFAULT_RULE,
        province: DEFAULT_RULE,
        postalCode: DEFAULT_RULE,
        bulkThreshold: {
          required: true,
          message: 'Please enter bulk threshold',
          trigger: 'blur',
        },
      },
    };
  },
  created() {
    if (this.isEdit) {
      getMerchant(this.$route.query.id).then((response) => {
        this.merchant = response.data;
        this.selectMerchantPics = [];
        this.primaryPic = this.merchant.images[0].imageUrl;
        for (let i = 1; i < this.merchant.images.length; i++) {
          this.inputPics.push({ url: this.merchant.images[i].imageUrl });
          this.selectMerchantPics.push(this.merchant.images[i].imageUrl);
        }
      });
    } else {
      this.merchant = { ...defaultMerchant };
    }
  },
  watch: {
    selectStartDay(newValue) {
      this.merchant.bulkStartDay = newValue[0];
    },
    selectEndDay(newValue) {
      this.merchant.bulkEndDay = newValue[0];
    },
  },
  methods: {
    onSubmit(formName) {
      const tagParam = this.merchant.merchantTags.find((_tagParam) => {
        if (_tagParam.tag == null || _tagParam.tag == '') {
          return true;
        }
        return false;
      });
      if (tagParam != null) {
        this.$message({
          message: 'tag不能为空',
          type: 'error',
          duration: 1000,
        });
        return;
      }
      if (this.merchant.merchantTags.length < 5) {
        this.$message({
          message: '需要至少5个tag',
          type: 'error',
          duration: 1000,
        });
        return;
      }
      if (this.merchant.color == '') this.merchant.color = null;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.merchant.images = [{ imageUrl: this.primaryPic }];
            this.selectMerchantPics.forEach((url) => {
              this.merchant.images.push({ imageUrl: url });
            });
            if (this.isEdit) {
              updateMerchant(this.merchant.id, this.merchant).then(
                (response) => {
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                    duration: 1000,
                  });
                  this.$router.back();
                },
              );
            } else {
              createMerchant(this.merchant).then((response) => {
                this.resetForm(formName);
                this.merchant = { ...defaultMerchant };
                this.$message({
                  message: '提交成功',
                  type: 'success',
                  duration: 1000,
                });
              });
            }
          });
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000,
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.merchant = { ...defaultMerchant };
      this.selectMerchantPics = [];
      this.merchant.merchantTags = [];
      this.merchant.images = [];
    },
    addTag() {
      this.merchant.merchantTags.push({ tag: '', primary: false });
    },
    removeTag(index) {
      this.merchant.merchantTags.splice(index, 1);
    },
    handlePlaceResult(res) {
      this.merchant.googlePlaceId = res.placeId;
      this.merchant.address = res.street;
      this.merchant.city = res.city;
      this.merchant.province = res.province;
      this.merchant.postalCode = res.postalCode;
    },
  },
};
</script>
<style>
</style>
